import {useEffect, useMemo, useState} from 'react';
import toast, {Toaster} from 'react-hot-toast';
import {useCookieState, useMemoizedFn} from 'ahooks';
import Cookies from 'js-cookie';
import {QrcodeIcon, MobileIcon} from '@/components/common';
import {base64Decode} from '@/utils/base64';
// import {homeUrl} from '@/utils/env';
import {externalLink} from '@/utils/env';
import { getToken } from '@/utils/auth';
import { isIframeLoaded } from '@/utils/isIframe';
import i18n, {getLanguage} from '@/locales/init';

import { checkToken } from '@/apis/login';
import WechatLogin from './WeChatLogin';
import NormalLogin from './NormalLogin';
import './index.less';


const Login = () => {
  const [isLong, setIsLong] = useState(false);
  const [isWechatLogin, setWechatLogin] = useState(false);
  const [tosAgreed, settOsAgreed] = useState(false);
  const [tosAgreedVisible, setTosAgreedVisible] = useState(true);
  // wx 扫码登录后，如果 token 未返回则需要绑定手机号。
  const [wxId, setWxId] = useState('');


  const redirectURI = useMemo(() => {
    const uri = (new URLSearchParams(window.location.search)).get('redirect_uri');
    if (!uri) {
      return null;
    }
    return base64Decode(uri);
  }, []);

  useEffect(() => {
    // 清理 org 页面的副作用，不清理不会影响业务
    Cookies.remove('_org');
  }, []);


  const domain = useMemo(() => {
    if (window.location.href.includes('dauyan.net')) {
      return '.dauyan.net';
    }
    if (window.location.href.includes('dauyan.com')) {
      return '.dauyan.com';
    }
    return;
  }, []);
  const [_, setToken] = useCookieState('token', {
    domain: domain,
    path: '/',
    // 超时时间，如果 isLong 为 true，则为 30d-1h，否则为 7d-1h
    expires: new Date(Date.now() + (isLong ? 2592000000 - 3600000 : 604800000 - 3600000)),
  });
  const [, setOrg] = useCookieState('org', {
    domain: domain,
    path: '/',
  });

  const currentLang = useMemo(() => {
    return getLanguage();
  },[]);

  const links = useMemo(() => {
    return {
      copyright: externalLink?.copyright?.[currentLang],
      privacyPolicy: externalLink?.privacyPolicy?.[currentLang],
      disclaimer: externalLink?.disclaimer?.[currentLang],
      userAgreement: externalLink?.userAgreement?.[currentLang],
    };
  },[currentLang]);

  const loginSuccess = (token: string, onlySetToken?: boolean) => {
    setOrg('');
    if (token){
      if (Cookies.get('token')) {
        Cookies.remove('token');
      }
      setToken(token);
    }
    if (onlySetToken){
      return;
    }
    if (isIframeLoaded()) {
      window.parent.postMessage({
        type: 'dauyanAuthStatus', data: {
          token: token,
          status: 'success',
        }
      }, '*');
      return;
    } else {
      if (!redirectURI){
        // eslint-disable-next-line i18n/no-chinese-character
        console.error('redirect_uri 不存在');
        window.location.href = 'https://www.dauyan.com/';
        return;
      }

      // 跳转到 redirect_uri
      window.location.href = redirectURI;
    }
  };

  useEffect(()=>{
    if (!getToken()){
      updateToken();
    }
  }, []);
  const updateToken = useMemoizedFn(()=>{
    checkToken().then(res=>{
      if (res?.data.token){
        loginSuccess(res.data.token, true);
      }
    });
  });


  function scanQrcode(token: string, studentId: number, wechatId: string, is_bind_mobile: boolean) {
    if (is_bind_mobile && token) {
      loginSuccess(token);
      return;
    }

    toast.success(i18n.t('common.dauyan_account_login_bind_phone_no'));
    setWechatLogin(false);
    setWxId(wechatId);
  }


  const changeLoginType = () => {
    setWechatLogin(!isWechatLogin);
    // 如果是微信登录，则默认勾选同意协议。 如果是微信扫码成功后，需要绑定手机号，则不勾选同意协议。
    // settOsAgreed(isWechatLogin);
  };
  return (
    <div className="login-container">
      
      <div className="login-desc">
        <div className="desc-title-wrap">
          <div className="desc-title">{i18n.t('common.dauyan_account_des_title')}</div>
          <div className="desc-content">{i18n.t('common.dauyan_account_des_content')}</div>
        </div>
      </div>
      <div className="login-main">
        {
          !wxId && tosAgreedVisible && (
            <div className="change-login-type" onClick={changeLoginType}>
              {isWechatLogin ? <MobileIcon/> : <QrcodeIcon/>}
              <div className="tips">{isWechatLogin ? i18n.t('common.dauyan_account_account_login') : i18n.t('common.dauyan_account_login_wechat')}</div>
            </div>
          )
        }

        {isWechatLogin ? (
          <WechatLogin
            scanQrcode={scanQrcode}
            isLong={isLong} 
            setIsLong={setIsLong}
            tosAgreed={tosAgreed}
            updateToken={updateToken}/>
        ) : (
          <NormalLogin
            wxCode={wxId}
            isLong={isLong} 
            setIsLong={setIsLong}
            setTosAgreed={settOsAgreed}
            loginSuccess={loginSuccess}
            tosAgreed={tosAgreed}
            setTosAgreedVisible={setTosAgreedVisible}/>
        )}
        {
          tosAgreedVisible && <div className="protocol-container">
            <input
              type="checkbox"
              className="checkspan"
              disabled={isWechatLogin}
              checked={isWechatLogin ? true : tosAgreed}
              onChange={e => settOsAgreed(e.target.checked)}
              placeholder={i18n.t('common.dauyan_account_term')}
            />

            <span className="protocol-content">
              {i18n.t('common.dauyan_account_term_login_agree')}
              <span className="protocol-mark">
                <a href={links.userAgreement} target="_blank" rel="noreferrer">{i18n.t('common.dauyan_account_term_user')}</a>
              </span>
              {i18n.t('common.dauyan_account_term_comma')}
              <span className="protocol-mark">
                <a href={links.privacyPolicy} target="_blank" rel="noreferrer">{i18n.t('common.dauyan_account_term_privacy')}</a>
              </span>
              {i18n.t('common.dauyan_account_term_comma')}
              <span className="protocol-mark">
                <a href={links.disclaimer} target="_blank" rel="noreferrer">{i18n.t('common.dauyan_account_term_disclaimer')}</a>
              </span>
              {i18n.t('common.dauyan_account_term_comma')}
              <span className="protocol-mark">
                <a href={links.copyright} target="_blank" rel="noreferrer">{i18n.t('common.dauyan_account_term_copyright')}</a>
              </span>
            </span>
          </div>
        }
        
      </div>
      <Toaster/>
    </div>
  );
};

export default Login;